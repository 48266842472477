.Nav {
  flex-shrink: 1;
}

.Nav ul {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}
.NavDekstop li {
  display: inline-block;
  padding: 10px 10px;
  text-align: right;
}
.NavDekstop li:not(:first-child) {
  margin-left: 10px;
}

.NavMobile li {
  display: block;
  text-align: left;
  margin: 0 20px 20px;
}

.Link {
  padding: 10px 0px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: .1em;
  font-size: 14px;
  text-decoration: none !important;
  position: relative;
  color: var(--white);
}
.Link:after {
  content: "";
  background: linear-gradient(to right, var(--link-active-color), var(--link-color) 70%);
  width: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  transition: .15s all ease-in-out;
}

.NavMobile .Link {
  color: var(--button-bg-color);
}

.NavActive .Link {
  color: var(--link-hover-color);
}

.Link:hover:after,
.NavActive .Link:after {
  width: 100%;
}

.cta .Link {
  border: 2px solid var(--border-color);
  padding-left: 20px;
  padding-right: 20px;
  color: var(--link-color);
}
.NavMobile .cta .Link,
.NavMobile .cta .Link:hover {
  color: var(--button-bg-color);
}

.cta .Link:after {
  display: none;
}

.cta .Link:hover {
  color: var(--white);
}

.NavOpen,.NavClose {
  width: 46px;
  height: 46px;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
}
.NavOpen svg {
  color: var(--white);
}
.NavClose {
  margin: 33px 15px 33px 0;
}
.NavClose svg {
  color: var(--link-color);
}
.NavClose:hover svg {
  color: var(--link-NavActive-color);
}

.NavFloating {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 320px;
  text-align: right;
  background-color: var(--text-color);
  opacity: 0.01;
  transform: translateX(320px);
}
.NavFloating__Open {
  transition: all 0.3s ease;
  opacity: 1;
  transform: translateX(0);
}