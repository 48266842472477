.Speaker {
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
}

.SpeakerImg {
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  overflow: hidden;
}
.SpeakerImg img {
  filter: grayscale(1);
  opacity: 0.5;
  transition: 0.5s all ease;
}
.SpeakerImg:hover img {
  filter: grayscale(0);
  opacity: 1;
  transform: scale(1.05);
}
.SpeakerImg img {
  width: 100%;
  height: 100%;
}

.SpeakerName {
  position: relative;
  font-size: 2rem;
  color: var(--heading-color);
  margin: 0 0 1.5rem;
}
.SpeakerTitle {
  display: block;
  font-size: 1rem;
  text-transform: uppercase;
  color: var(--link-color);
}
.SpeakerDescrip {
  font-size: 1.3rem;
  line-height: 1.7;
  color: var(--text-color);
}

.SpeakerFollow {
  font-size: 1.2rem;
  color: var(--text-color);
  display: flex;
  align-items: center;
}
.SpeakerFollow a {
  margin: 0 10px;
  height: 20px;
}
.SpeakerFollow svg {
  width: 20px;
}
.SpeakerFollow path {
  fill: var(--link-color);
  transition: 0.15s fill ease-out;
}
.SpeakerFollow a:hover path {
  fill: var(--link-hover-color);
}

.SpeakerWrap {
  display: -webkit-box;
  -webkit-line-clamp: 13;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.SpeakerWrapShow {
  padding: 10px;
  margin-top: 1.5rem;
}

@media (min-width: 992px) {
  .Speaker {
    flex-direction: row;
    align-items: flex-start;
  }
  .Speaker__Right {
    flex-direction: row-reverse;
  }

  .SpeakerImg,
  .SpeakerDetails {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .SpeakerDetails {
    padding-left: 1rem;
  }

  .SpeakerName {
    left: -50px;
  }

  .SpeakerTitle,
  .SpeakerWrap,
  .SpeakerFollow {
    padding-left: 3rem;
  }

  .SpeakerWrapShow {
    margin-left: 3rem;
  }
}
