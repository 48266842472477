.Header {
  max-width: 1400px;
  padding: 1rem 15px;
  margin: auto;
  display: flex;
  align-items: center;
}

.Logo {
  font-weight: 400;
  flex: 1 0 16.66%;
  font-size: 1rem;
}

.Logo a {
  display: flex;
  white-space: nowrap;
  align-items: center;
}

.Logo .GDG {
  color: var(--heading-color);
}

.Logo .Community {
  color: var(--link-color);
}

.Logo img {
  width: 50px;
  margin-right: 0.25rem;
}

@media (min-width: 380px) {
  .Logo {
    font-size: 1.5rem;
  }
}

@media (min-width: 648px) {
  .Logo {
    font-size: 2rem;
  }
}
