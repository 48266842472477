:root {
  --white: #fff;
  --bg-color: #232531;
  --heading-color: #fff;
  --text-color: #cfcfd1;
  --link-color: #ff5733;
  --link-hover-color: #e62900;
  --link-active-color: #c70039;
  --border-color: #383b4f;
  --button-color: #fff;
  --button-bg-color: #3f4046;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: var(--link-color);
  text-decoration: none;
  transition: .15s all ease-out;
}

a:hover {
  color: var(--link-hover-color);
}

a:active {
  transform: translateY(1px);
}

.App {
  overflow: hidden;
}